export const PREFETCH_TYPES = {
    DNS_PREFETCH: "dns-prefetch",
    PREFETCH: "prefetch",
    PRECONNECT: "preconnect"
};
const isProduction = process.env.NODE_ENV === "production";
const PLATFORM_STAGING_CDN_URL = "https://multicdn.mondaystaging.com";
const PLATFORM_PROD_CDN_URL = "https://cdn.monday.com";
export const PLATFORM_CDN_URL = isProduction ? PLATFORM_PROD_CDN_URL : PLATFORM_STAGING_CDN_URL;
export const PLATFORM_DOMAIN = isProduction ? "monday.com" : "mondaystaging.com";
export const PLATFORM_BUNDLES_ENDPOINT = `https://auth.${PLATFORM_DOMAIN}/layout/get_bundles_data`;
export const PLATFORM_BUNDLES_ASSETS_TO_FETCH = [
    "soft_signup",
    "signup",
    "main",
    "components",
    "vendors",
    "externals"
];
