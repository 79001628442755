import styled from "@emotion/styled";
import { ALWAYS_ON_TOP, BASE_TEMPLATE_ROOT } from "constants/z-index";
export const StyledContactSalesStickyButtonWrapper = styled.div`
  position: fixed;
  top: 80vh;
  right: 0;
  width: 230px;
  z-index: ${ALWAYS_ON_TOP};
`;
export const StyledTemplateContentWrapper = styled.div`
  position: relative;
  z-index: ${BASE_TEMPLATE_ROOT};

  &.with-background-color {
    background-color: white;
  }
`;
export const StyledAccessibilityWrapper = styled.div`
  position: relative;
  z-index: ${BASE_TEMPLATE_ROOT + 1};
`;
